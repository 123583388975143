const depression = {
  quote1:
    '<p>“Do not let what you cannot do interfere with what you can do.”</p><p>JOHN WOODEN</p>',
  overview:
    'All of us feel “blue” or sad once in a while. When we lose a loved one or an upsetting event happens it is normal to feel sad and grieve. Time helps us heal. However, when people feel extreme sadness for longer than two weeks and their normal functioning is affected, they may need to visit their doctor or mental health professional for a thorough evaluation. Experts on the study of depression reported in the Textbook of Mood Disorders (2006) that “depression is the second most disabling of all medical disorders worldwide and it is predicted to top the burden-of-disease tables by 2020.”<br/><br/>Depression can be a biological and a genetic predisposition. However, changes in the body’s chemistry also influence mood and thought processes. Other factors can also contribute to depression such as certain physical and medical conditions, sleeping difficulties, consumption of alcohol and drugs, or traumatic and stressful events.<br/><br/>When people are depressed, their view of real life can become cloudy. Their thinking is negative and they don’t seem motivated to do anything or enjoy what they used to enjoy.',
  treatment:
    'Usually treatments for depression include medications and psychotherapy. If clients are experiencing severe depression and/or are suicidal, it is important they talk to someone they trust so that they can be taken immediately to a hospital.<br/><br/>If clients are experiencing mild depression, they may need medication, but it is recommended that they see a psychotherapist so that they can learn needed skills to improve their overall outlook on life.<br/><br>If you are experiencing depression, Cognitive Behavioral Therapy (CBT), along with ACT (Acceptance & Commitment Therapy) which also includes mindfulness training, will be used to help you decrease the symptoms.<br/><br/>During treatment you will talk, learn, walk, dance, listen, sing, write, imagine, role play, and possibly do other activities such as draw or paint. It all depends on how you are able to learn and assimilate what is being taught. The most important aspect of treatment is that you commit to doing your part so that your goals for treatment can be met. As usual, support from family members or friends is recommended to be able to maintain the skills you attain during treatment.',
  symptoms: [
    'Depressed mood – feeling sad or empty. Irritable mood in children and adolescents',
    'Significant weight loss when not dieting or weight gain',
    'Trouble sleeping or excessive sleeping',
    'Fatigue or loss of energy',
    'Feelings of worthlessness and inappropriate guilt',
    'Diminished ability to think or concentrate, or indecisiveness',
    'Inactivity and withdrawal from usual activities',
    'Feelings of hopelessness and helplessness',
    'Recurring thoughts of death or suicide',
  ],
};

export default depression;
