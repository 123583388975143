import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../components/Layout';
import Depression from '../../components/Specialties/components/Depression';

export default () => (
  <React.Fragment>
    <SEO
      title="Depression | Mindset Family Therapy"
      description="If you are experiencing mild depression, you may need medication, but it is recommended that you see a psychotherapist so you can learn needed skills to improve your overall outlook on life. If you are experiencing depression, Cognitive Behavioral Therapy (CBT), along with ACT (Acceptance & Commitment Therapy) which also includes mindfulness training, will be used to help you decrease the symptoms."
      image="https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1498532633/depressionImage.jpg"
      pathname="/specialties/depression"
      article
    />
    <Layout>
      <Depression />
    </Layout>
  </React.Fragment>
);
